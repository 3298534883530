import * as Yup from "yup";
import {
  CButton,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";

const initialValues = {
  pincode: "",
  country: "",
  area: "",
  city: "",
  district: "",
  state: "",
  area1: "",
  Landmark1: "",
  city1: "",
  state1: "",
  country1: "",
  pincode1: "",
  area2: "",
  Landmark2: "",
  city2: "",
  state2: "",
  country2: "",
  pincode2: "",
};

const Adress = (props) => {
  const { newOtpVerifiedData } = useSelector((state) => state?.companyReducer);

  const [customerAddress, setCustomerAddress] = useState(
    props?.formData || initialValues
  );

  const onChangeValue = (e) => {
    const { name, value } = e.target;
    setCustomerAddress({ ...customerAddress, [name]: value });
  };

  const handleSubmit = (values) => {
    props.updateFormData("address", customerAddress);
    props.setKey("Identification", values);
  };

  // useEffect(() => {
  //   if (!_.isEmpty(newOtpVerifiedData)) {
  //     const count = newOtpVerifiedData?.data?.split_address?.country || "";
  //     const pin = newOtpVerifiedData?.data?.split_address?.pincode || "";
  //     const dist = newOtpVerifiedData?.data?.split_address?.dist || "";
  //     const sta = newOtpVerifiedData?.data?.split_address?.state || "";
  //     const street = newOtpVerifiedData?.data?.split_address?.street || "";
  //     const cty = newOtpVerifiedData?.data?.split_address?.po || "";

  //     setCustomerAddress((prevMainData) => ({
  //       ...prevMainData,
  //       country: count,
  //       pincode: pin,
  //       district: dist,
  //       state: sta,
  //       area: street,
  //       city: cty,
  //     }));
  //   }
  // }, [newOtpVerifiedData]);

  const {
    pincode,
    country,
    area,
    city,
    district,
    state,
    area1,
    Landmark1,
    city1,
    state1,
    country1,
    pincode1,
    area2,
    Landmark2,
    city2,
    state2,
    country2,
    pincode2,
  } = customerAddress;

  return (
    <CCardBody>
      <CContainer>
        <CForm onSubmit={(event) => handleSubmit(event)} autoComplete="off">
          <div>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="area">
                  Plot/Building/Flat No.
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="area"
                  placeholder="Plot/Building/Flat No. Same as Aadhar Card"
                  name="area"
                  value={area}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="district">
                  Landmark
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="district"
                  placeholder="Landmark of Aadhar Card Address"
                  name="district"
                  value={district}
                  onChange={onChangeValue}
                  // disabled
                  required
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="city">
                  City
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="city"
                  placeholder="City Same as Aadhar Card"
                  name="city"
                  value={city}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>

              <CCol xs={6}>
                <CFormLabel htmlFor="state">
                  State
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="state"
                  placeholder="State Same as Aadhar Card"
                  name="state"
                  value={state}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="country">
                  Country
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="country"
                  placeholder="Country Same as Aadhar Card"
                  name="country"
                  value={country}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="pincode">
                  Pin Code
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="pincode"
                  placeholder="Pin Code Same as Aadhar Card"
                  name="pincode"
                  value={pincode}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>
          </div>
          <div>
            <CRow className="pt-20">Current Residency : </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="area1">
                  Plot/Building/Flat No.
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="area1"
                  placeholder="Plot/Building/Flat No."
                  name="area1"
                  value={area1}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="district">
                  Landmark
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="Landmark"
                  placeholder="Landmark of Current Residency"
                  name="Landmark1"
                  value={Landmark1}
                  required
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="city">
                  City
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="city"
                  placeholder="City"
                  name="city1"
                  value={city1}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>

              <CCol xs={6}>
                <CFormLabel htmlFor="state">
                  State
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state1"
                  value={state1}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="country">
                  Country
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="country"
                  placeholder="Country"
                  name="country1"
                  value={country1}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="pincode">
                  Pin Code
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="pincode"
                  placeholder="Pin Code"
                  name="pincode1"
                  value={pincode1}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>
          </div>
          <div>
            <CRow className="pt-20">Current Working Address : </CRow>
            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="area">
                  Plot/Building/Flat No.
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="area"
                  placeholder="Plot/Building/Flat No."
                  name="area2"
                  value={area2}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="district">
                  Landmark
                  <span className="red-star">{` `}*</span>
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="Landmark"
                  placeholder="Landmark of Current Working Address"
                  name="Landmark2"
                  value={Landmark2}
                  required
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="city">
                  City
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="city"
                  placeholder="City"
                  name="city2"
                  value={city2}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>

              <CCol xs={6}>
                <CFormLabel htmlFor="state">
                  State
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state2"
                  value={state2}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>

            <CRow className="pt-20">
              <CCol xs={6}>
                <CFormLabel htmlFor="country">
                  Country
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="text"
                  id="country"
                  placeholder="Country"
                  name="country2"
                  value={country2}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
              <CCol xs={6}>
                <CFormLabel htmlFor="pincode">
                  Pin Code
                  {/* <span className="red-star">{` `}*</span> */}
                </CFormLabel>
                <CFormInput
                  type="number"
                  id="pincode"
                  placeholder="Pin Code"
                  name="pincode2"
                  value={pincode2}
                  onChange={onChangeValue}
                  // disabled
                />
              </CCol>
            </CRow>
          </div>
          <div className="identification_btn">
            <CButton className="next_btn" type="submit">
              Next{" "}
            </CButton>
          </div>
        </CForm>
      </CContainer>
    </CCardBody>
  );
};
Adress.propTypes = {
  formData: PropTypes.object.isRequired,
  updateFormData: PropTypes.func.isRequired,
  setKey: PropTypes.func.isRequired,
};
export default Adress;
