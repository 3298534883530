import ProductList from "src/pages/Products/ProductList";
import Users from "../pages/company";
import Dashboard from "../pages/Dashboard";
import UserOperations from "src/pages/staffSection";
import CustomerProfile from "src/pages/CustomerDetails/CustomerProfile";
import LoanType from "src/pages/loanType";
import Collection from "src/pages/collection";
import AddStaffForm from "src/pages/staffSection/AddStaffForm";
import FinalDetailsForm from "src/pages/customer/finalDetailsForm";
import ViewCustomerProfileInfo from "src/pages/LoanData/ViewCustomerProfileInfo";
import BranchList from "src/pages/branches";
import DirectorList from "src/pages/directors";
import DirectorListById from "src/pages/directors/capital";
import AgentDetailsById from "src/pages/staffSection/AddStaffForm/agentDetails";
import PaidCollection from "src/pages/collection/paidCollections";
import AddForm from "src/pages/customer/addForm";
import EmiRecords from "src/pages/CustomerDetails/emiRecord";
import Dues from "src/pages/CustomerDetails/due";
import PaymentRecords from "src/pages/CustomerDetails/paymentRecord";
import AddGroupForm from "src/pages/group/AddGroup";
import GroupList from "src/pages/group/groupList";
import JournalEntries from "src/acounting/pages/journalEntries";
import ContraEntries from "src/acounting/pages/contraEntry";
import OpeningBalanceList from "src/acounting/pages/openingBalance";
import ProfitAndLossList from "src/acounting/pages/profitAndLossChart";
import Ledger from "src/acounting/pages/ledger";
import CustomerListForVeriFication from "src/pages/otherVerification/customerListForVerification";
import JournalEntryAddForm from "src/acounting/pages/journalEntries/journalEntryAddForm";
import JEntryData from "src/acounting/pages/journalEntries/journalEntryList";
import MasterList from "src/acounting/pages/master/masterList";
import ProfileDetails from "src/components/profile/profile";
import EmiDataForStatement from "src/pages/emiStatement/emiData";
import RecieptEntryForm from "src/acounting/pages/reciept/recieptEntryForm";
import PaymentForm from "src/acounting/pages/payment/paymentForm";
import NewRegistrationCreateForm from "src/pages/customerData/newRegistrationCreateForm";
import { element } from "prop-types";
import EmiDetails from "src/pages/EmiDetails";
import LoanStatus from "src/pages/loanDetails";
import PendingEmiDetails from "src/pages/PendingEmiDetails";
import PaymentCollection from "src/pages/CustomerDetails/newSection";
import BankStatement from "src/pages/bankStatement/bankStatement";
import Banking from "src/pages/bankStatement/banking";
import DisbursementDetails from "src/pages/disbursmentDetails/disbursment-details";

const AppRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/customerdetailsform", name: "Final Details Form", element: FinalDetailsForm },
  { path: "/users", name: "Users", element: Users },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/productlist", name: "Product List", element: ProductList },
  { path: "/branches", name: "branches", element: BranchList },
  { path: "/newregisterform", name: "Creation", element: NewRegistrationCreateForm },
  { path: "/directors", name: "director", element: DirectorList },
  { path: "/emistatement", name: "EMI", element: EmiDataForStatement },
  { path: "/verification", name: "Verification", element: CustomerListForVeriFication },
  { path: "/journalentrieslist", name: "Journal Entries", element: JournalEntries },
  { path: "/journalAddForm", name: "Journal Add Form", element: JournalEntryAddForm },
  { path: "/recieptForm", name: "Reciept", element: RecieptEntryForm },
  { path: "/paymentForm", name: "Payment", element: PaymentForm },
  { path: "/journaldata", name: "Journal Data", element: JEntryData },
  { path: "/profile", element: ProfileDetails },
  { path: "/contraentrieslist", name: "Contra Entries", element: ContraEntries },
  { path: "/balancelist", name: "Balance List", element: OpeningBalanceList },
  { path: "/profitandlosslist", name: "Profit And Loss List", element: ProfitAndLossList },
  { path: "/ledger", name: "Ledger", element: Ledger },
  { path: "/directorsCapital", name: "director", element: DirectorListById },
  { path: "/staff", name: "Staffs", element: UserOperations },
  { path: "/owndetails", name: "Staffs", element: AgentDetailsById },
  { path: "/emirecords", name: "Emirecord", element: EmiRecords },
  { path: "/payment-collection", name: "Payment Collection", element: PaymentCollection },
  { path: "/due", name: "Due", element: Dues },
  { path: "/paymentrecords", name: "Paymentrecord", element: PaymentRecords },
  { path: "/customers", name: "Customers", element: AddForm },
  { path: "/masteraccount", name: "Master", element: MasterList },
  { path: "/bankstatement", name: "Master", element: BankStatement },
  { path: "/banking", name: "Master", element: Banking },
  { path: "/customer-profile", name: "Customer Details", element: CustomerProfile, },
  { path: "/emi/loanType", name: "Loan Type", element: LoanType },
  { path: "/pendingcollection", name: "Collection", element: Collection },
  { path: "/paidcollection", name: "Collection", element: PaidCollection },
  { path: "/viewcustomerprofileinfo", name: "ViewCustomerProfileInfo", element: ViewCustomerProfileInfo },
  { path: "/staff/addstaff", name: "staff/addstaff", element: AddStaffForm },
  { path: "/group/addgroup", name: "group/addgroup", element: AddGroupForm },
  { path: "/group/grouplist", name: "group/grouplist", element: GroupList },
  { path: "/staff/edit", name: "staff/edit", element: AddStaffForm },
  { path: "/emi-details/", name: "emi Details", element: EmiDetails },
  { path: "/loan-status", name: "emi Details", element: LoanStatus },
  { path: "/pending-emi-details", name: "PendingEmiDetails", element: PendingEmiDetails },
  { path: "/disbursmet-details", name: "Disbursment Details", element: DisbursementDetails }
];

export default AppRoutes;
