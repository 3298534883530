import React, { useEffect, useRef, useState } from "react";
import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormInput, CFormSelect, CModal, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFile, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-js-pagination";
import ViewDetails from "../loanDetails";
import { getAllCustomers } from "src/api/allApis";
import AddForm from "src/pages/customer/addForm";
import { useReactToPrint } from "react-to-print";
import Vakilkaro from "src/assets/brand/vakilkaro";
import { CSVLink } from "react-csv";
import { ModalBody, ModalHeader, Tooltip } from "react-bootstrap";
import AddCustomerGroup from "./updateCustomerGroup";
import { getAllGroup } from "src/redux/actions/userAction";
import ForImageUpload from "src/pages/customerData/forImageUpload";
import { COMPANY_NAME } from "src/services/const";
import { formatDate } from "src/services/helpers";

const CustomerData = () => {
  const dispatch = useDispatch();
  const { AllGroups } = useSelector((state) => state?.userReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [showLoanSummary, setShowLoanSummary] = useState(false);
  const [showCustomerAddForm, setShowCustomerAddForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customersPendingStatus, setCustomersPendingStatus] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [showSelectAndSearch, setShowSelectAndSearch] = useState(true);
  const [customerFilter, setCustomerFilter] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [printState, setPrintState] = useState(false);
  const SchemaName = sessionStorage.getItem("schemaName");
  const getEmployeeId = sessionStorage.getItem("emplyeeId");
  const employeeRole = sessionStorage.getItem("role");
  const branchId = sessionStorage.getItem("branc");
  const [filteredRows, setFilteredRows] = useState([]);
  const [visibleCustomerGroup, setVisibleCustomerGroup] = useState(false);
  const [activeCustomerid, setActiveCustomerid] = useState(0);

  const getAllCompanyData = () => {
    const payload = { schemaName: SchemaName };
    getAllCustomers(payload).then((res) => { setCustomersPendingStatus(res?.data) });
  };

  useEffect(() => { getAllCompanyData(); }, []);

  useEffect(() => { if (printState) { generatePDF(); } }, [printState]);

  useEffect(() => {
    if (employeeRole == "agent") {
      const CustomerFilterData = customersPendingStatus?.filter(
        (m) => m?.employeeId === Number(getEmployeeId)
      );
      setCustomerFilter(CustomerFilterData);
    } else { setCustomerFilter(customersPendingStatus); }
  }, [customersPendingStatus]);

  const filterRows = () => {
    if (Array.isArray(customerFilter)) {
      const filteredData = customerFilter.filter(customer => {
        const GuarentorName = JSON.parse(customer?.nominee);
        const reference = JSON.parse(customer?.customerreference);
        const aadharNumber = customer?.aadharNumber?.toString()?.toLowerCase();
        const mobile = customer?.phoneNumber?.toString()?.toLowerCase();
        const fullName = customer?.firstName?.toLowerCase();
        const fatherName = customer?.fatherName?.toLowerCase();
        const grandFatherName = customer?.grandFatherName?.toLowerCase();
        const branchName = customer?.branch?.branchName?.toLowerCase();
        const branchCode = customer?.branch?.branchCode?.toLowerCase();
        const GuaranName = GuarentorName?.GuarantorName?.toLowerCase();
        const GuarantorMobileNumber = GuarentorName?.mobileNumber?.toLowerCase();
        const referenceName = reference?.refName?.toLowerCase();
        const referenceMobile = reference?.referencePhoneNumber?.toLowerCase();
        const loanAccountNum = customer?.loanAccountNumber.toLowerCase();
        const createdAt = formatDate(new Date(customer?.createdAt).toISOString().split("T")[0]);
        const searchTerm = searchInput?.toLowerCase();
        if (!searchTerm) return true;
        if (!isNaN(searchTerm)) return aadharNumber.includes(searchTerm) || mobile.includes(searchTerm);
        if (/^\d{2}-\d{2}-\d{4}$/.test(searchTerm)) return createdAt.includes(searchTerm);
        return [fullName, fatherName, grandFatherName, branchName, branchCode, GuaranName, GuarantorMobileNumber, referenceName, referenceMobile, loanAccountNum].some(value => value?.includes(searchTerm));
      });
      setFilteredRows(filteredData);
    } else { setFilteredRows([]) }
  };

  useEffect(() => {
    filterRows();
    return () => { setFilteredRows([]) };
  }, [customerFilter, searchInput]);

  const handlePageChange = (pageNumber) => { setCurrentPage(pageNumber) }

  const customerBranchData = filteredRows?.filter((item) => branchId == item?.branchId);

  const dataSource = employeeRole === "employee" ? customerBranchData : filteredRows;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = dataSource?.slice(indexOfFirstRow, indexOfLastRow);

  const handleViewForm = (customerdata) => {
    setCustomerData(customerdata);
    setShowLoanSummary(true);
  };

  const handleAddForm = (customerdata) => {
    setCustomerData(customerdata);
    setShowCustomerAddForm(true);
  };

  const [uploadDocument, setUploadDocument] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleUploadDocument = (customerdata) => {
    setUploadDocument(customerdata?.id);
    setShowUploadModal(true);
  };

  const handleSearchChange = (e) => { setSearchInput(e.target.value) }
  const componentPDF = useRef();
  const download_btn = () => { setPrintState(true) }

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setPrintState(false),
  });

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const objData = { schemaName: SchemaName };
    dispatch(getAllGroup(objData));
  }, [SchemaName, dispatch]);

  return (
    <>
      <CRow className="content">
        <CModal
          className="fullPageModal"
          backdrop="static"
          visible={showUploadModal}
          onClose={() => setShowUploadModal(false)}
          aria-labelledby="StaticBackdropExampleLabel"
          size="xl"
        >
          <ModalHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>Upload your Document Here</div>
              <div
                style={{ flexShrink: 0, cursor: "pointer" }}
                onClick={() => setShowUploadModal(false)}
              >
                Close
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <ForImageUpload
              uploadDocument={uploadDocument}
              setShowUploadModal={setShowUploadModal}
            />
          </ModalBody>
        </CModal>
        <CCol xs={12}>
          {showCustomerAddForm && (
            <>
              <CModal
                className="fullPageModal"
                backdrop="static"
                visible={showCustomerAddForm}
                onClose={() => setShowCustomerAddForm(false)}
                aria-labelledby="StaticBackdropExampleLabel"
                size="xl"
              >
                <AddForm
                  customerData={customerData}
                  showCustomerAddForm={showCustomerAddForm}
                  setShowCustomerAddForm={setShowCustomerAddForm}
                  getAllCompanyData={getAllCompanyData}
                />
              </CModal>
            </>
          )}
          {showLoanSummary ? (
            <ViewDetails
              customerData={customerData}
              setShowLoanSummary={setShowLoanSummary}
              showLoanSummary={showLoanSummary}
            />
          ) : (
            <>
              <CRow>
                <CCol xs={12}>
                  <CCard>
                    <CCardHeader>
                      <strong>Customer Details</strong>
                    </CCardHeader>
                    <CCardBody>
                      <CRow>
                        <CCol xs={12}>
                          <CCard>
                            <div className="search_bar">
                              <div className="search-inner">
                                {showSelectAndSearch && (
                                  <div>
                                    <CFormSelect
                                      onChange={handleRowsPerPageChange}
                                      size="sm"
                                      className="mb-3"
                                      style={{ cursor: "pointer" }}
                                      aria-label="Small select example"
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="30">30</option>
                                    </CFormSelect>
                                  </div>
                                )}

                                <div style={{ display: "flex" }}>
                                  {showSelectAndSearch && (
                                    <>
                                      <CFormInput
                                        type="text"
                                        size="sm"
                                        placeholder="Enter desired value for searching"
                                        aria-label="sm input example"
                                        style={{ margin: "0px 0px 16px 0px" }}
                                        onChange={handleSearchChange}
                                        value={searchInput}
                                      />
                                      <span
                                        style={{
                                          border: " 1px solid #d1c5c5",
                                          borderRadius: "4px",
                                          margin: "0px 5px 16px -1px",
                                          padding: "1px 8px 0px 8px",
                                          background: "#ebedef",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i className="fa fa-search"></i>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="btn_add mt-0 mb-14">
                                <CButton
                                  className="add-btn mr-10 w-auto"
                                  onClick={download_btn}
                                >
                                  Download
                                </CButton>
                                <CSVLink
                                  data={currentRows === undefined ? " " : currentRows}
                                  filename={"customer-details.csv"}
                                >
                                  <CButton className="add-btn w-auto m-0">
                                    CSV
                                  </CButton>
                                </CSVLink>
                              </div>
                            </div>
                            <CCardBody>
                              <div>
                                <div
                                  ref={printState ? componentPDF : null}
                                  style={{ padding: printState ? "20px" : "0px" }}
                                >
                                  {printState && (
                                    <div className="print-div">
                                      <div><h3>Riyms</h3></div>
                                      <div><p>{COMPANY_NAME}</p></div>
                                    </div>
                                  )}
                                  <CTable
                                    color="dark"
                                    hover
                                    className=""
                                    responsive
                                  >
                                    <CTableHead>
                                      <CTableRow>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-1"
                                        >
                                          S. No.
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Loan Date
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-1"
                                        >
                                          Loan ID
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Member Name
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Father Name
                                        </CTableHeaderCell>
                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          GrandFather Name
                                        </CTableHeaderCell>

                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Branch Name
                                        </CTableHeaderCell>

                                        <CTableHeaderCell
                                          scope="col"
                                          className="col-md-2"
                                        >
                                          Mobile
                                        </CTableHeaderCell>

                                        {employeeRole !== "employee" &&
                                          !printState && (
                                            <CTableHeaderCell
                                              scope="col"
                                              className="col-md-1"
                                            >
                                              Action
                                            </CTableHeaderCell>
                                          )}
                                        {employeeRole !== "employee" &&
                                          !printState && (
                                            <CTableHeaderCell
                                              scope="col"
                                              className="col-md-2"
                                            >
                                              Edit
                                            </CTableHeaderCell>
                                          )}
                                        {!printState && (
                                          <CTableHeaderCell
                                            scope="col"
                                            className="col-md-2"
                                          >
                                            Documents Upload
                                          </CTableHeaderCell>
                                        )}
                                      </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                      {Array.isArray(dataSource) &&
                                        currentRows?.map((company, index) => {
                                          return (
                                            <CTableRow key={index}>
                                              <CTableDataCell>
                                                {indexOfFirstRow + index + 1}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {formatDate((company?.createdAt).split("T")[0])}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.loanAccountNumber}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.firstName}{" "}
                                                {company?.middleName ===
                                                  "undefined"
                                                  ? ""
                                                  : company?.middleName}{" "}
                                                {company?.lastName}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.fatherName
                                                  ? company?.fatherName
                                                  : "--"}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.grandFatherName
                                                  ? company?.grandFatherName
                                                  : "--"}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.branch?.branchName}
                                              </CTableDataCell>
                                              <CTableDataCell>
                                                {company?.phoneNumber}
                                              </CTableDataCell>

                                              {employeeRole !== "employee" &&
                                                !printState && (
                                                  <CTableDataCell className="col-md-1">
                                                    <button
                                                      style={{ gap: "2px" }}
                                                      className="view-button btn_view margin_auto"
                                                      onClick={() => handleViewForm(company)}
                                                    >
                                                      <FontAwesomeIcon
                                                        role="button"
                                                        icon={faEye}
                                                        size="1x"
                                                        className="viewIcon "
                                                      />
                                                      Proceed
                                                    </button>
                                                  </CTableDataCell>
                                                )}
                                              {employeeRole !== "employee" &&
                                                !printState && (
                                                  <CTableDataCell className="col-md-2">
                                                    <button
                                                      style={{ gap: "2px" }}
                                                      className="view-button btn_view margin_auto"
                                                      onClick={() =>
                                                        handleAddForm(company)
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        role="button"
                                                        icon={faFile}
                                                        size="1x"
                                                        className="viewIcon "
                                                      />
                                                      Edit
                                                    </button>
                                                  </CTableDataCell>
                                                )}
                                              {!printState && (
                                                <CTableDataCell className="col-md-2">
                                                  <button
                                                    style={{ gap: "2px" }}
                                                    className="view-button btn_view margin_auto"
                                                    onClick={() =>
                                                      handleUploadDocument(
                                                        company
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      role="button"
                                                      icon={faFile}
                                                      size="1x"
                                                      className="viewIcon "
                                                    />
                                                    Upload
                                                  </button>
                                                </CTableDataCell>
                                              )}
                                            </CTableRow>
                                          );
                                        })}
                                    </CTableBody>
                                  </CTable>
                                </div>

                                <div className="pagin-div">
                                  <div
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Showing {indexOfFirstRow + 1} to{" "}
                                    {Math?.min(
                                      indexOfLastRow,
                                      dataSource?.length
                                    )}{" "}
                                    of {dataSource?.length} entries
                                  </div>
                                  <div className="pagination-container">
                                    <ReactPaginate
                                      activePage={currentPage}
                                      itemsCountPerPage={rowsPerPage}
                                      totalItemsCount={dataSource?.length}
                                      pageRangeDisplayed={5}
                                      onChange={handlePageChange}
                                      prevPageText="Previous"
                                      nextPageText="Next"
                                      firstPageText="First"
                                      lastPageText="Last"
                                      innerClass="pagination"
                                      itemClass="page-item"
                                      linkClass="page-link"
                                    />
                                  </div>
                                </div>
                              </div>
                            </CCardBody>
                          </CCard>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </>
          )}
        </CCol>
        <CCol xs={12}></CCol>
        {visibleCustomerGroup && (
          <AddCustomerGroup
            setVisibleCustomerGroup={setVisibleCustomerGroup}
            visibleCustomerGroup={visibleCustomerGroup}
            activeCustomerid={activeCustomerid}
            setActiveCustomerid={setActiveCustomerid}
            AllGroupsLoan={AllGroups?.data}
            getAllCompanyData={getAllCompanyData}
          />
        )}
      </CRow>
    </>
  );
};

export default CustomerData;
