import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import LoanStatus from "./Details";
import Disbursement from "./disbursement";
import { useSelector } from "react-redux";
import SantialLetterUpload from "./sensalLetter";
import { useDispatch } from "react-redux";
import { getAllEmiByCustomerId } from "src/redux/actions/emiAction";
import AddLoanForm from "./CustomerProfile/addLoan/addLoanForm";
import LoanDetailsData from "./verificationTab/loandetailsData";
import DownloadDocuments from "./downloadDocuments";

const ViewDetails = ({ setShowLoanSummary, customerData }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const { emiData } = useSelector((state) => state?.emiReducer);
  const SchemaName = useState(sessionStorage.getItem("schemaName"));

  useEffect(() => {
    const obj = {
      schemaName: SchemaName,
      customerId: customerData?.id,
    };
    dispatch(getAllEmiByCustomerId(obj));
  }, []);
  const [key, setKey] = useState("LOANSTATUS");

  const handleAddLoan = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div className="close_btn">
        <div></div>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setShowLoanSummary(false)}
        ></button>
      </div>

      <CRow>
        <AddLoanForm
          setVisible={setVisible}
          visible={visible}
          customerData={customerData}
        />

        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardHeader className="header_head_btn">
              <span>Loan Details</span>
              <span className="btn_profile">
                <CButton
                  onClick={handleAddLoan}
                  className="d-grid gap-2 col-6 mx-auto mb-3"
                >
                  Add Loan
                </CButton>
              </span>
            </CCardHeader>

            <CCardBody>
              <div className="tab_er">
                <div style={{ width: "5%" }}></div>
                <div style={{ width: "100%" }}>
                  <CRow>
                    <CCol xs={12}>
                      <CCard className="mb-4">
                        <Tabs
                          id="controlled-tab-example"
                          activeKey={key}
                          onSelect={(k) => setKey(k)}
                          className="mb-3 tabs_customer_details"
                        >
                          <Tab eventKey="LOANSTATUS" title="DETAILS">
                            {LoanStatus && (
                              <LoanStatus
                                customerData={customerData}
                                emiData={emiData}
                              />
                            )}
                          </Tab>

                          <Tab eventKey="LOANDETAILS" title="LOAN STATUS">
                            {LoanDetailsData && (
                              <LoanDetailsData
                                customerData={customerData}
                                emiData={emiData}
                                setKey={setKey}
                              />
                            )}
                          </Tab>

                          <Tab eventKey="SANTIALLETTER" title="SANCTION LETTER">
                            {SantialLetterUpload && (
                              <SantialLetterUpload
                                customerData={customerData}
                                emiData={emiData}
                              />
                            )}
                          </Tab>
                          <Tab eventKey="DISBURSEMENT" title="DISBURSEMENT">
                            {Disbursement && (
                              <Disbursement
                                customerData={customerData}
                                emiData={emiData}
                              />
                            )}
                          </Tab>
                          <Tab eventKey="downloadDocuments" title="DOCUMENTS">
                            {DownloadDocuments && (
                              <DownloadDocuments
                                customerData={customerData}
                                emiData={emiData}
                              />
                            )}
                          </Tab>
                        </Tabs>
                      </CCard>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
ViewDetails.propTypes = {
  setShowLoanSummary: PropTypes.bool.isRequired,
  customerData: PropTypes.object.isRequired,
};

export default ViewDetails;
