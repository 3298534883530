import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
  CFormInput,
  CFormLabel,
  CRow,
  CCol,
} from "@coreui/react";
import { getLoanId } from "src/redux/actions/customerAction";
import {
  getAllEmiByCustomerId,
  setPaymentFalse,
  updateEmi,
} from "src/redux/actions/emiAction";
import { addPayment } from "src/api/customerApis";
const PropTypes = require("prop-types");

const formatDateToDisplay = (dateStr) => {
  const [year, month, day] = dateStr.split("-");
  return `${day}-${month}-${year}`;
};

const formatDateForDatabase = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return `${year}-${month}-${day}`;
};

const formatDateForInput = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return `${year}-${month}-${day}`;
};
const PaymentModel = ({
  visible,
  setVisible,
  customerData,
  dueData,
  loanId,
  // setCurrSelect1,
  setRefresh,
  selectedMode,
  emidetail,
  totalPayment,
  AllEmiPaidBalanceAmount
}) => {
  const dispatch = useDispatch();
  const emiamount = emidetail?.[0]?.emiAmount;
  const SchemaName = sessionStorage.getItem("schemaName");
  const [amount, setAmount] = useState();
  const [penalty, setPenalty] = useState(dueData?.penalty ?? 0);
  const [discount, setDiscount] = useState();
  const employee = sessionStorage.getItem("emplyeeId");
  const BRANCH_ID = sessionStorage.getItem("branc");
  const [paymentDate, setPaymentDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    dispatch(setPaymentFalse());
    const today = new Date().toISOString().split("T")[0];
    setPaymentDate(formatDateToDisplay(today));
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (Number(value) <= totalPayment) {
      setAmount(value);
    } else {
      alert(`Amount cannot exceed ${totalPayment}`);
    }
  };

  const handleDateChange = (e) => {
    const dateStr = e.target.value;
    const [year, month, day] = dateStr.split("-");
    if (
      dateStr &&
      year.length === 4 &&
      month.length === 2 &&
      day.length === 2
    ) {
      setPaymentDate(formatDateToDisplay(dateStr));
    }
  };
  const loadingDis = () => {
    setIsLoading(!isLoading);
  }

  const handleSubmit = async () => {
    loadingDis();
    const formattedDate = formatDateForDatabase(paymentDate);
    const emiamounts = amount !== undefined ? amount : emiamount;
    const total = Number(emiamounts) + Number(penalty);

    const data = {
      schemaName: SchemaName,
      status: "pending",
      receivedBy: employee != "null" ? employee : 0,
      paymentDate: formattedDate,
      paymentAmount: selectedMode === "pay" ? (total !== undefined ? total : emiamount) : 0,
      penaltyAmount: selectedMode === "penalty" || selectedMode === "pay" ? (penalty ? penalty : 0) : 0,
      discount: selectedMode === "discount" ? discount : 0,
      customerId: customerData?.id,
      paymentMode: selectedMode === "pay" ? "online" : "online",
      emiId: dueData?.id,
      loanId: loanId,
      branchId: BRANCH_ID != "null" ? BRANCH_ID : "1",
    };

    try {
      const response = await addPayment(data)
      if (response.status === "success") {
        const obj = {
          id: dueData?.id,
          schemaName: SchemaName,
          customerId: customerData?.id,
          emiStatus: "paid",
        };

        await dispatch(updateEmi(obj));

        const obje = {
          schemaName: SchemaName,
          customerId: customerData?.id,
        };
        await dispatch(getLoanId(obje));
        await dispatch(getAllEmiByCustomerId(obje));
        setVisible(false);
      }
    } catch (error) {
      console.error("Payment submission failed:", error);
    } finally {
      setRefresh(true);
    }
  };


  return (
    <>
      <CModal
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="ScrollingLongContentExampleLabel2"
      >
        <CModalHeader>
          <CModalTitle id="ScrollingLongContentExampleLabel2">
            {`${customerData?.firstName}`}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol xs={10}>
              <CFormLabel htmlFor="currentDate">Payment Date</CFormLabel>

              <CFormInput
                type="date"
                name="Payment Date"
                value={formatDateForInput(paymentDate)}
                onChange={handleDateChange}
                placeholder="dd-mm-yyyy"
                disabled={role === "employee"}
              />
            </CCol>
          </CRow>
          {selectedMode === "pay" && (
            <CRow>
              <CCol xs={10}>
                <CFormLabel htmlFor="amount">Amount</CFormLabel>
                <CFormInput
                  type="text"
                  value={amount}
                  placeholder={`${emiamount}`}
                  onChange={handleAmountChange}
                  disabled={role === "employee" && !AllEmiPaidBalanceAmount}
                />
              </CCol>
            </CRow>
          )}
          {selectedMode === "penalty" ||
            (selectedMode === "pay" && (
              <CRow>
                <CCol xs={10}>
                  <CFormLabel htmlFor="penalty">Penalty Amount</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="enter Amount"
                    value={penalty}
                    onChange={(e) => setPenalty(e.target.value)}
                  />
                </CCol>
              </CRow>
            ))}
          {selectedMode === "discount" && (
            <CRow>
              <CCol xs={10}>
                <CFormLabel htmlFor="discount">Discount Amount</CFormLabel>
                <CFormInput
                  type="text"
                  placeholder="enter Amount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </CCol>
            </CRow>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton onClick={handleSubmit} color="primary" disabled={isLoading}>
            Done
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

PaymentModel.propTypes = {
  visible: PropTypes.bool.isRequired,
  // setCurrSelect1: PropTypes.bool,
  loanId: PropTypes.number.isRequired,
  setVisible: PropTypes.func.isRequired,
  setHandleRefresh: PropTypes.func,
  setRefresh: PropTypes.func,
  customerData: PropTypes.object.isRequired,
  dueData: PropTypes.object.isRequired,
  emiData: PropTypes.array.isRequired,
  emidetail: PropTypes.array,
  selectedMode: PropTypes.string,
  totalPayment: PropTypes.number,
  AllEmiPaidBalanceAmount: PropTypes.bool,
};

export default PaymentModel;
